import React from "react"
import _ from "lodash"
import tw, { styled, css } from "twin.macro"
import "styled-components/macro"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import Gallery from "@browniebroke/gatsby-image-gallery"
import Header from "../components/Header"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import InnerHTML from "dangerously-set-html-content"

const Container = styled.div`
  ${tw`px-5 pt-5`}
`
const ContentListContainer = styled.div`
  ${tw`mt-8`}
`
const ScriptDiv = styled(InnerHTML)`
  ${tw`prose`}
  ${tw`max-w-full 
  `}

  img, iframe {
    ${tw`w-full my-5`}
  }
`
const MarkdownDiv = styled.div`
  ${tw`prose`}
  ${tw`max-w-full 
  `}

  img, iframe {
    ${tw`w-full my-5`}
  }
`

const ListContainer = styled.ul`
  ${tw`mb-8`}
`
const ListItem = styled.li`
  ${tw`flex justify-between items-center text-xl px-4 py-4 border-b border-gray-50`}
  ${({ isEven }) => (isEven ? tw`bg-transparent` : tw`bg-black/10`)}
`
const DetailContainer = styled.div`
  ${tw`flex flex-col justify-center`}
`
const Name = styled.div`
  ${tw`font-bold`}
`
const TicketDescription = styled.div`
  ${tw`text-xs opacity-60`}
`
const ValueContainer = styled.div`
  ${tw` font-bold flex`}

  ${tw`text-[#9561a9]/90`}
`

// the Gallery component from the gatsby-image-gallery package has negative margins
const GalleryContainer = styled.div`
  ${tw`mx-[11px] my-5`}
`

const InnerPageOne = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, hero, tabs, contentList },
    },
  },
  location,
}) => {
  const breakpoints = useBreakpoint()
  const isMidScreen =
    Object.values(breakpoints).reduce((a, b) => (b ? a + 1 : a), 0) > 1
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && <Header title={title} heroData={hero} tabsData={tabs} />}
        <ContentListContainer>
          {contentList.map((contentSection, index) => {
            const { type } = contentSection

            if (type === "gallery") {
              const { images } = contentSection

              const imgs = images || []
              const imgCount = imgs.length
              const galleryImgs = imgs.map(img => img.childImageSharp)

              const rem3 = imgCount % 3
              const rem4 = imgCount % 4

              let colDivisor = 3
              if (imgCount >= 4) {
                if (rem4 === 0) {
                  colDivisor = 4
                } else if (rem3 === 0) {
                  colDivisor = 3
                } else if (rem4 > rem3) {
                  colDivisor = 4
                } else {
                  colDivisor = 3
                }
              }
              return (
                <GalleryContainer key={index}>
                  <Gallery
                    images={galleryImgs}
                    colWidth={50}
                    mdColWidth={100 / colDivisor}
                  />
                </GalleryContainer>
              )
            } else if (type === "script") {
              const { htmlScript } = contentSection
              return <ScriptDiv key={index} html={htmlScript} />
            } else if (type === "markdown") {
              const { markdown } = contentSection
              return (
                <MarkdownDiv
                  key={index}
                  dangerouslySetInnerHTML={{ __html: markdown }}
                />
              )
            } else if (type === "draftPicksList") {
              const { listItem } = contentSection

              return (
                <ListContainer key={index}>
                  {listItem.map((item, idx) => (
                    <ListItem key={idx} isEven={idx % 2 === 0}>
                      <DetailContainer>
                        <Name>{item.name}</Name>
                        {!isMidScreen && (
                          <TicketDescription>{item.teams}</TicketDescription>
                        )}
                      </DetailContainer>
                      {isMidScreen && (
                        <ValueContainer>
                          <span>{item.teams}</span>
                        </ValueContainer>
                      )}
                    </ListItem>
                  ))}
                </ListContainer>
              )
            }
          })}
        </ContentListContainer>
      </Container>
    </Layout>
  )
}

export default InnerPageOne

export const pageQuery = graphql`
  query SubPageOneQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        contentList {
          type
          markdown
          htmlScript
          images {
            childImageSharp {
              thumb: gatsbyImageData(
                aspectRatio: 1
                width: 350
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER }
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image {
            childImageSharp {
              thumb: gatsbyImageData(placeholder: BLURRED)
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
